export enum EntityVerificationStatus {
  approved = "approved",
  pending = "pending",
  rejected = "rejected",
  sent = "sent"
}

export enum VerificationType {
  kyc = "kyc",
  kyb = "kyb",
  kycFree = "kycFree"
}

export class EntityVerificationDto {
  link: string | null;
  status: EntityVerificationStatus | undefined;
  type: VerificationType | undefined;
  sessionId: string | undefined;

  constructor(
    link: string,
    status: EntityVerificationStatus | undefined,
    type: VerificationType | undefined,
    sessionId: string | undefined
  ) {
    this.link = link;
    this.status = status;
    this.type = type;
    this.sessionId = sessionId;
  }
}
